import React, { useState, useEffect } from "react";
import {
  Autocomplete,
  Button,
  Container,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Tab,
  Tabs,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
  FormGroup,
  FormControlLabel,
  Checkbox,
  TextField,
  Paper,
  Grid,
  Modal,
  Box,
  Collapse
} from "@mui/material";
import careersData from "./data/careers.json";
import specializationsData from "./data/specializations.json";
import talentsData from "./data/talents.json";
import skillsData from "./data/skills.json";
import weaponsData from "./data/weapons.json"
import qualities from "./data/qualities.json"
import MarkedText from "./Util/MarkedText";
import getFullBoughtTalents from "./Util/GetFullBoughtTalents"
import characteristics from "./data/characteristics.json"
import signatureAbilitiesData from "./data/signatureAbilities.json"
import signatureAbilityNodesData from "./data/signatureAbilityNodes.json"
import modStatChange from "./Util/ModStatChange";

let careers = careersData
let specializations = specializationsData
let talents = talentsData
let skills = skillsData
let sigAbils = signatureAbilitiesData
let sigAbNodes = signatureAbilityNodesData
let weapons = weaponsData

let statChange

function TableComponent(props) {
  const {
    tableData,
    character,
    handleCharacterChange,
    checkedCells,
    setCheckedCells,
    outsideTalents,
    setOutsideTalents,
    checkedSigCells,
    setCheckedSigCells,
    handleDedicationClear,
    setSkillSelectOpen,
    setSelectedTalent,
    spec,
    sig,
    handleSigChange,
    DedicationDropdown,
    TinkererDropdown,
    JuryRiggedDropdown,
    JurySelectionDropdown,
    JuryAdvantageDropdown,
    SigVehicleDropdown,
    IchorDropdown,
    activeTab
  } = props;
  const tableRows = [
    tableData[0].Talents,
    tableData[1].Talents,
    tableData[2].Talents,
    tableData[3].Talents,
    tableData[4].Talents,
  ];

  function toggleCell(row, column) {
    const handleSelectedCareerSkillsRemove = (talentKey) => {
      let selectedCareerSkills = { ...character.SelectedCareerSkills } || {};

      Object.keys(selectedCareerSkills).forEach(key => {
        if (Array.isArray(selectedCareerSkills[key])) {
          const filteredArray = selectedCareerSkills[key].filter(tal => tal !== talentKey);
          if (filteredArray.length > 0) {
            selectedCareerSkills[key] = filteredArray;
          }
        }
        if (selectedCareerSkills[key] === talentKey) {
          delete selectedCareerSkills[key]
        }
      });
      return selectedCareerSkills
    }

    const updatedCells = [...checkedCells];
    updatedCells[row][column] = !updatedCells[row][column];
    setCheckedCells(updatedCells);
    if (updatedCells[row][column]) {
      if (talents.find((t) => t.Key === tableData[row].Talents[column]).ChooseCareerSkills?.SkillCount) {
        setSelectedTalent(talents.find((t) => t.Key === tableData[row].Talents[column]))
        setSkillSelectOpen(true)
      }
      let vehicles = JSON.parse(JSON.stringify(character.Vehicles))
      let signatureVehicle = vehicles.find((v) => v.SignatureVehicle);
      if (signatureVehicle) {
        let oldIndex = vehicles.findIndex((v) => v.SignatureVehicle);
        if (tableData[row].Talents[column] === "FANCPAINT") {
          signatureVehicle.BaseMods.push({ MiscDesc: "Fancy Paint Job: Upgrade all Charm, Deception, and Negotiation checks made in the presence of this Vehicle once." });
        }
        if (tableData[row].Talents[column] === "NOTTODAY") {
          signatureVehicle.BaseMods.push({ MiscDesc: "Not Today: Once per session, spend a Destiny Point to save this Vehicle from destruction." });
        }
        if (tableData[row].Talents[column] === "CUSTLOAD") {
          signatureVehicle.HP = parseInt(signatureVehicle.HP) + 2
        }
        if (tableData[row].Talents[column] === "TUNEDTHRUST") {
          signatureVehicle.Handling = parseInt(signatureVehicle.Handling) + 1
        }
        if (tableData[row].Talents[column] === "BOLSTARMOR") {
          signatureVehicle.Armor = parseInt(signatureVehicle.Armor) + 1
        }
        if (tableData[row].Talents[column] === "CUSTCOOL") {
          signatureVehicle.SystemStrain = parseInt(signatureVehicle.SystemStrain) + 2
        }
        if (tableData[row].Talents[column] === "FORTVAC") {
          signatureVehicle.HullTrauma = parseInt(signatureVehicle.HullTrauma) + 1
        }
        if (tableData[row].Talents[column] === "REINFRAME") {
          signatureVehicle.Massive = 1
        }
        if (tableData[row].Talents[column] === "OVERAMMO") {
          if (signatureVehicle.VehicleWeapons) {
            for (let w in signatureVehicle.VehicleWeapons) {
              let weap = signatureVehicle.VehicleWeapons[w]
              if (weap.Qualities && weap.Qualities.find((q) => q.Key === "LIMITEDAMMO")) {
                let ammo = weap.Qualities.find((q) => q.Key === "LIMITEDAMMO")?.Count
                if (ammo) {
                  weap.Qualities.find((q) => q.Key === "LIMITEDAMMO").Count = parseInt(ammo) + 1
                }
              } else {
                let stats = weapons.find((w) => w.Key === weap.Key)
                if (stats.Qualities?.find((q) => q.Key === "LIMITEDAMMO")) {
                  let newQual = { Key: "LIMITEDAMMO", Count: parseInt(stats.Qualities.find((q) => q.Key === "LIMITEDAMMO").Count) + 1 }
                  weap.Qualities ? weap.Qualities.push(newQual) : weap.Qualities = [newQual]
                }
              }
            }
          }
        }
        vehicles[oldIndex] = signatureVehicle;
        handleCharacterChange({
          XP: character.XP - 5 * (row + 1),
          Vehicles: vehicles
        });
      } else {
        handleCharacterChange({
          XP: character.XP - 5 * (row + 1),
        });
      }

    } else {
      let dedications = { ...character.Dedications }
      let weapons = [...character.Weapons]
      let armor = [...character.Armor]
      let gear = [...character.Gear]
      let vehicles = [...character.Vehicles]
      let riggableItems = weapons.concat(armor)
      let tinkerableItems = riggableItems.concat(gear)
      let ichorableItems = weapons.filter(w => w.SkillKey === "BRAWL" || w.SkillKey === "MELEE")

      let selectedCareerSkills = { ...character.SelectedCareerSkills }
      if (tableData[row].Talents[column] === "DEDI") {
        dedications = handleDedicationClear();
      }
      if (tableData[row].Talents[column] === "TINK") {
        let previouslyTinkered = tinkerableItems.find((i) => i.Tinkered === character.Specializations[activeTab].Key + row + column);
        if (previouslyTinkered) {
          let type = character.Weapons.find((w) => previouslyTinkered.inventoryID === w.inventoryID) ? "Weapons" : character.Armor.find((a) => previouslyTinkered.inventoryID === a.inventoryID) ? "Armor" : "Gear";
          let newCat = [...character[type]];
          previouslyTinkered = newCat.find((i) => i.Tinkered === character.Specializations[activeTab].Key + row + column);
          delete previouslyTinkered.Tinkered;
          previouslyTinkered.HP -= 1;
          switch (type) {
            case "Weapons":
              weapons = newCat
              break;
            case "Armor":
              armor = newCat
              break;
            case "Gear":
              gear = newCat
              break;
          }
        }
      }
      if (tableData[row].Talents[column] === "ICHBLADECOTR") {
        let previouslyIchored = ichorableItems.find(w => w.Ichored)
        if (previouslyIchored) {
          delete previouslyIchored.Ichored
          let index = weapons.findIndex(w => w.inventoryID === previouslyIchored.inventoryID)
          weapons[index] = previouslyIchored
        }
      }
      if (tableData[row].Talents[column] === "JURY") {
        let previouslyRigged = riggableItems.find((i) => i.Rigged === character.Specializations[activeTab].Key + row + column);

        if (previouslyRigged) {
          let type = character.Weapons.find((w) => previouslyRigged.inventoryID === w.inventoryID) ? "Weapons" : character.Armor.find((a) => previouslyRigged.inventoryID === a.inventoryID) ? "Armor" : "Gear";
          let newCat = [...character[type]];

          delete previouslyRigged.Rigged;

          if (previouslyRigged.RigQual) {
            previouslyRigged.BaseMods = previouslyRigged.BaseMods.filter((m) => !m.MiscDesc?.includes("Reduce the [AD] cost of the "))
            delete previouslyRigged.RigQual
          }

          if (previouslyRigged.RigMod) {
            [previouslyRigged, statChange] = modStatChange(previouslyRigged, { Key: previouslyRigged.RigMod }, true);
          }

          delete previouslyRigged.RigMod

          let previouslyRiggedIndex = newCat.findIndex((i) => i.inventoryID === previouslyRigged.inventoryID);

          if (previouslyRiggedIndex !== -1) {
            newCat[previouslyRiggedIndex] = previouslyRigged;
          }
          switch (type) {
            case "Weapons":
              weapons = newCat
              break;
            case "Armor":
              armor = newCat
              break;
            case "Gear":
              gear = newCat
              break;
          }
        }

      }
      let signatureVehicle = vehicles.find((v) => v.SignatureVehicle);
      if (signatureVehicle) {
        let oldIndex = vehicles.findIndex((v) => v.SignatureVehicle);
        if (tableData[row].Talents[column] === "SIGVEH") {
          delete signatureVehicle.SignatureVehicle;
          signatureVehicle.BaseMods = signatureVehicle.BaseMods?.filter((m) => !m.MiscDesc.includes("Signature Vehicle:"));
        }
        if (tableData[row].Talents[column] === "FANCPAINT") {
          signatureVehicle.BaseMods = signatureVehicle.BaseMods?.filter((m) => m.MiscDesc !== "Fancy Paint Job: Upgrade all Charm, Deception, and Negotiation checks made in the presence of this Vehicle once.");
        }
        if (tableData[row].Talents[column] === "NOTTODAY") {
          signatureVehicle.BaseMods = signatureVehicle.BaseMods?.filter((m) => m.MiscDesc !== "Not Today: Once per session, spend a Destiny Point to save this Vehicle from destruction.");
        }
        if (tableData[row].Talents[column] === "CUSTLOAD") {
          signatureVehicle.HP -= 2
        }
        if (tableData[row].Talents[column] === "TUNEDTHRUST") {
          signatureVehicle.Handling -= 1
        }
        if (tableData[row].Talents[column] === "BOLSTARMOR") {
          signatureVehicle.Armor -= 1
        }
        if (tableData[row].Talents[column] === "CUSTCOOL") {
          signatureVehicle.SystemStrain -= 2
        }
        if (tableData[row].Talents[column] === "FORTVAC") {
          signatureVehicle.HullTrauma -= 1
        }
        if (tableData[row].Talents[column] === "REINFRAME") {
          delete signatureVehicle.Massive
        }
        if (tableData[row].Talents[column] === "OVERAMMO") {
          if (signatureVehicle.VehicleWeapons) {
            for (let w in signatureVehicle.VehicleWeapons) {
              let weap = signatureVehicle.VehicleWeapons[w]
              if (weap.Qualities && weap.Qualities.find((q) => q.Key === "LIMITEDAMMO")) {
                let ammo = weap.Qualities.find((q) => q.Key === "LIMITEDAMMO")?.Count
                if (ammo) {
                  weap.Qualities.find((q) => q.Key === "LIMITEDAMMO").Count = parseInt(ammo) - 1
                }
              }
            }
          }
        }
        vehicles[oldIndex] = signatureVehicle;
      }
      if (talents.find((t) => t.Key === tableData[row].Talents[column]).ChooseCareerSkills?.SkillCount) {
        selectedCareerSkills = handleSelectedCareerSkillsRemove(tableData[row].Talents[column])
      }
      handleCharacterChange({
        XP: character.XP + 5 * (row + 1),
        Dedications: dedications,
        SelectedCareerSkills: selectedCareerSkills,
        Armor: armor,
        Weapons: weapons,
        Gear: gear,
        Vehicles: vehicles
      });
    }
  }

  let signatureRows = sig?.AbilityRows || []

  function toggleSigCell(row, column) {
    const updatedCells = [...checkedSigCells];
    updatedCells[row][column] = !updatedCells[row][column];
    setCheckedSigCells(updatedCells);
    let xpDiff = parseInt(signatureRows[row].Costs[column])
    if (updatedCells[row][column]) {
      handleCharacterChange({
        XP: character.XP - xpDiff
      });
    } else {
      handleCharacterChange({
        XP: character.XP + xpDiff
      });
    }
  }

  const isConnected = (rowIndex, columnIndex, directions, checkedCells, sigOne) => {
    if (rowIndex === 0) { return true }
    const cellDirections = directions[columnIndex];
    if (cellDirections) {
      const { Up, Down, Left, Right } = cellDirections;
      if (
        (sigOne && rowIndex === 1 && Up && checkedCells[0][0]) ||
        (Up && (checkedCells[rowIndex - 1][columnIndex] || outsideTalents[rowIndex - 1][columnIndex])) ||
        (Down && (checkedCells[rowIndex + 1][columnIndex] || outsideTalents[rowIndex + 1][columnIndex])) ||
        (Left && (checkedCells[rowIndex][columnIndex - 1] || outsideTalents[rowIndex][columnIndex - 1])) ||
        (Right && (checkedCells[rowIndex][columnIndex + 1] || outsideTalents[rowIndex][columnIndex + 1]))
      ) {
        return true;
      }
    }
    if (outsideTalents[rowIndex][columnIndex]) {
      let newOutsideTalents = [...outsideTalents]
      newOutsideTalents[rowIndex][columnIndex] = false
      setOutsideTalents(newOutsideTalents)
    }
    return false;
  };

  const { Bought: boughtTalents, universalForce } = getFullBoughtTalents(character);
  const unrankedOwned = (talent, rowIndex, columnIndex, directions, checkedCells) => {
    if (!talent.Ranked && boughtTalents.find(t => t.key === talent.Key)) {
      if (!checkedCells[rowIndex][columnIndex] && isConnected(rowIndex, columnIndex, directions, checkedCells)) {
        let newOutsideTalents = [...outsideTalents]
        newOutsideTalents[rowIndex][columnIndex] = true
        setOutsideTalents(newOutsideTalents)
      }
      return true
    }
    return false
  }

  const headerColors = {
    Passive: "#021c61",
    Active: "#670d0d",
  };

  const nodesAlign = (sigNodes, specNodes) => {
    for (let n in sigNodes) {
      if (sigNodes[n] && !specNodes[n]) {
        return false
      }
    }
    return true
  }

  const validSignatures = () => {
    if (!character.Career.Specializations.includes(character.Specializations[activeTab].Key)) { return [] }
    let validSigs = []
    let careerSigs = sigAbils.filter((a) => a.Career === character.Career.Key)
    for (let s in careerSigs) {
      let sig = careerSigs[s]
      if (nodesAlign(sig.MatchingNodes, checkedCells[4])) {
        validSigs.push(sig)
      }
    }
    return (validSigs)
  }

  const getSize = (span) => {
    return (span * (12 * span - 2)) / span ** 2;
  };

  return (
    <TableContainer>
      <Table style={{ tableLayout: "fixed" }}>
        <TableBody>
          {tableRows.map((row, rowIndex) => (
            <TableRow key={rowIndex}>
              {row.map((cell, columnIndex) => (
                <TableCell
                  key={columnIndex}
                  sx={{
                    verticalAlign: "top",
                    padding: "0px",
                    borderBottom: "0px",
                    position: "relative",
                    overflow: "hidden",
                  }}
                >
                  <Grid
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      flexDirection: "column",
                    }}
                  >
                    <Paper
                      square
                      sx={{
                        border: "6px solid gray",
                        width: "0px",
                        minHeight: "20px",
                      }}
                      style={{
                        visibility: tableData[rowIndex].Directions[
                          columnIndex
                        ].Up
                          ? "visible"
                          : "hidden",
                      }}
                    ></Paper>
                  </Grid>
                  <Grid container>
                    <Grid
                      item
                      xs={1}
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        height: "50px",
                      }}
                    >
                      <Paper
                        square
                        sx={{
                          border: "6px solid gray",
                          height: "0px",
                          minWidth: "90px",
                        }}
                        style={{
                          visibility: tableData[rowIndex].Directions[
                            columnIndex
                          ].Left
                            ? "visible"
                            : "hidden",
                        }}
                      ></Paper>
                    </Grid>
                    <Grid item xs={10} position="relative">
                      {talents.find((talent) => talent.Key === cell).ForceTalent && <svg viewBox="0 0 128 128" style={{
                        position: 'absolute',
                        top: '-.8em',
                        left: '-.8em',
                        width: '2.4em',
                        height: '2.4em',
                        fill: headerColors[talents.find((talent) => talent.Key === cell).ActivationValue]
                      }}
                        aria-hidden="true"
                        role="img"
                        class="iconify iconify--noto"
                        preserveAspectRatio="xMidYMid meet"
                        transform="matrix(-1, 0, 0, -1, 0, 0)">
                        <g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier">
                          <path d="M110.8 23.1L81.7 63.5c-.4.7-.4 1.5 0 2.2l29.1 42.3c1.2 1.8-1 3.9-2.8 2.8L65.1 82.2c-.7-.4-1.5-.4-2.2 0L20 110.8c-1.8 1.2-3.9-1-2.8-2.8l29.1-42.3c.4-.7.4-1.5 0-2.2L17.2 23.1c-1.2-1.8 1-3.9 2.8-2.8l42.9 26.6c.7.4 1.5.4 2.2 0L108 20.3c1.8-1.1 3.9 1 2.8 2.8z" ></path><path d="M66 5.6l8.4 46.5c.2.8.8 1.4 1.5 1.5l46.5 8.4c2.1.5 2.1 3.5 0 3.9l-46.6 8.4c-.8.2-1.4.8-1.5 1.5L66 122.4c-.4 2.1-3.5 2.1-3.9 0l-8.4-46.6c-.2-.8-.8-1.4-1.5-1.5L5.6 66c-2.1-.4-2.1-3.5 0-3.9l46.5-8.4c.8-.2 1.4-.8 1.5-1.5L62 5.6c.5-2.1 3.5-2.1 4 0z" ></path><path d="M52.65 57.02c-2.26 1.44-6.3 1.44-6.3 3.18c0 1.28 2.31 1.28 4.77 1.28c3.49-.05 6.41-.36 7.84-1.54c1.44-1.18 1.9-3.13 2.05-7.07c.15-4.72-.21-5.54-1.38-5.54c-1.55 0-2.52 6.92-6.98 9.69z" opacity=".75" ></path>
                        </g>
                      </svg>}
                      <Paper sx={{ overflow: "visible", paddingBottom: "10px" }}>
                        <Grid
                          container
                          sx={{
                            background: headerColors[talents.find((talent) => talent.Key === cell).ActivationValue],
                            borderTopLeftRadius: (theme) => theme.shape.borderRadius,
                            borderTopRightRadius: (theme) => theme.shape.borderRadius,
                          }}
                        >
                          <Grid item xs={12} sm={3} md={2}>
                            <Checkbox
                              sx={{
                                padding: "3px",
                                "&.MuiCheckbox-colorPrimary": {
                                  color: "#FFFFFF",
                                },
                                "&.Mui-checked": {
                                  color: "#FFFFFF",
                                },
                                "&.Mui-disabled": {
                                  color: "#808080",
                                },
                                fontSize: {
                                  xs: '.7rem',
                                  sm: '1rem',
                                }
                              }}
                              disabled={
                                !isConnected(
                                  rowIndex,
                                  columnIndex,
                                  tableData[rowIndex].Directions,
                                  checkedCells,
                                ) || (outsideTalents[rowIndex] && outsideTalents[rowIndex][columnIndex])
                              }
                              checked={
                                (checkedCells[rowIndex] && checkedCells[rowIndex][columnIndex]) || (outsideTalents[rowIndex] && outsideTalents[rowIndex][columnIndex]) ||
                                (isConnected(
                                  rowIndex,
                                  columnIndex,
                                  tableData[rowIndex].Directions,
                                  checkedCells,
                                ) && unrankedOwned(talents.find((talent) => talent.Key === cell), rowIndex, columnIndex, tableData[rowIndex].Directions, checkedCells))
                              }
                              onChange={() => toggleCell(rowIndex, columnIndex)}
                            />
                          </Grid>
                          <Grid item xs={12} sm={9} md={10} sx={{ display: 'flex', alignItems: 'center' }}>
                            <Typography
                              variant="h6"
                              color="white"
                              sx={{
                                position: 'relative',
                                paddingRight: talents.find((talent) => talent.Key === cell).Conflict ? '25px' : '5px',
                                width: '100%',
                                fontSize: {
                                  xs: '.7rem',
                                  sm: '1rem',
                                  md: '1.2rem',
                                }
                              }}
                            >
                              <span style={{ wordWrap: 'break-word' }}>
                                {talents.find((talent) => talent.Key === cell).Name}
                              </span>
                              {talents.find((talent) => talent.Key === cell).Conflict && <span
                                style={{
                                  position: 'absolute',
                                  right: 3,
                                  top: 0
                                }}
                                dangerouslySetInnerHTML={{
                                  __html: MarkedText.renderer('[CONFLICT]'),
                                }}
                              />}
                            </Typography>
                          </Grid>
                        </Grid>
                        <Box
                          sx={{
                            display: 'inline-block',
                            padding: '5px',
                            fontSize: {
                              xs: '0.6rem',
                              sm: '1rem'
                            },
                          }}
                          dangerouslySetInnerHTML={{
                            __html: MarkedText.renderer(
                              talents.find((talent) => talent.Key === cell).Description
                            ),
                          }}
                        />
                        <br />
                        {cell === "DEDI" && checkedCells[rowIndex][columnIndex] && <DedicationDropdown />}
                        {cell === "SIGVEH" && checkedCells[rowIndex][columnIndex] && <SigVehicleDropdown />}
                        {cell === "ICHBLADECOTR" && checkedCells[rowIndex][columnIndex] && <IchorDropdown />}
                        {cell === "TINK" && checkedCells[rowIndex][columnIndex] && <TinkererDropdown coordinates={(rowIndex.toString() + columnIndex)} />}
                        {cell === "JURY" && checkedCells[rowIndex][columnIndex] && <JuryRiggedDropdown coordinates={(rowIndex.toString() + columnIndex)} />}
                        {cell === "JURY" && checkedCells[rowIndex][columnIndex] && <JurySelectionDropdown coordinates={(rowIndex.toString() + columnIndex)} />}
                        {cell === "JURY" && checkedCells[rowIndex][columnIndex] &&
                          (character.Weapons.concat(character.Armor)).find((i) => i.Rigged && (i.Rigged === character.Specializations[activeTab].Key + rowIndex + columnIndex) && i.RigMod === "QUALADVSUB") &&
                          <JuryAdvantageDropdown coordinates={(rowIndex.toString() + columnIndex)} />}
                        {talents.find((talent) => talent.Key === cell).ChooseCareerSkills?.SkillCount && checkedCells[rowIndex][columnIndex] &&
                          <Button onClick={() => {
                            setSelectedTalent(talents.find((talent) => talent.Key === cell))
                            setSkillSelectOpen(true)
                          }}>Select Skills</Button>
                        }
                        <div style={{ textAlign: "right" }}>
                          <Paper
                            sx={{
                              background: headerColors[talents.find((talent) => talent.Key === cell).ActivationValue],
                              display: "inline-block",
                              paddingLeft: "5px",
                              paddingRight: "5px",
                              marginRight: "10px",
                              position: "absolute",
                              bottom: "-10px",
                              right: 0,
                              zIndex: 3
                            }}
                          >
                            <Typography sx={{ fontSize: { xs: '0.7rem', sm: '1rem' } }}>
                              Cost: {tableData[rowIndex].Cost}
                            </Typography>
                          </Paper>
                        </div>
                      </Paper>
                    </Grid>
                    <Grid
                      item
                      xs={1}
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        height: "50px",
                      }}
                    >
                      <Paper
                        square
                        sx={{
                          border: "6px solid gray",
                          height: "0px",
                          minWidth: "90px",
                        }}
                        style={{
                          visibility: tableData[rowIndex].Directions[
                            columnIndex
                          ].Right
                            ? "visible"
                            : "hidden",
                        }}
                      ></Paper>
                    </Grid>
                  </Grid>
                  <Grid
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      flexDirection: "column",
                      minHeight: "20px",
                    }}
                  >
                    <Paper
                      square
                      sx={{
                        border: "6px solid gray",
                        width: "0px",
                        height: "100%",
                        position: "absolute",
                      }}
                      style={{
                        visibility: tableData[rowIndex].Directions[
                          columnIndex
                        ].Down
                          ? "visible"
                          : "hidden",
                      }}
                    ></Paper>
                  </Grid>
                </TableCell>
              ))}
            </TableRow>
          ))}
          <TableRow>
            {validSignatures()[0] && <TableCell><Typography>Signature Ability:</Typography></TableCell>}
            {validSignatures().map((s) => (<>
              {(sig?.Key !== s.Key) && <TableCell>
                <Button onClick={(e) => handleSigChange(s)}>Attach {s.Name}</Button>
              </TableCell>}
            </>)
            )}
            {sig?.Key && <TableCell><Button onClick={(e) => handleSigChange({})}>Remove {sig.Name}</Button></TableCell>}
          </TableRow>
          {signatureRows.map((row, rowIndex) => (
            <TableRow key={rowIndex}>
              {row.Abilities.map((cell, columnIndex) => (
                <TableCell
                  key={columnIndex}
                  sx={{
                    verticalAlign: "top",
                    padding: "0px",
                    borderBottom: "0px",
                    position: "relative",
                    overflow: "hidden",
                  }}
                  colSpan={signatureRows[rowIndex].AbilitySpan[columnIndex]}
                >
                  <Grid
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      flexDirection: "column",
                    }}
                  >
                    <Paper
                      square
                      sx={{
                        border: "6px solid gray",
                        width: "0px",
                        minHeight: "20px",
                      }}
                      style={{
                        visibility: signatureRows[rowIndex].Directions[
                          columnIndex
                        ].Up
                          ? "visible"
                          : "hidden",
                      }}
                    ></Paper>
                  </Grid>
                  <Grid container>
                    <Grid
                      item
                      xs={1 / signatureRows[rowIndex].AbilitySpan[columnIndex]}
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        height: "50px"
                      }}
                    >
                      <Paper
                        square
                        sx={{
                          border: "6px solid gray",
                          height: "0px",
                          minWidth: "90px",
                        }}
                        style={{
                          visibility: signatureRows[rowIndex].Directions[
                            columnIndex
                          ].Left
                            ? "visible"
                            : "hidden",
                        }}
                      ></Paper>
                    </Grid>
                    <Grid item xs={getSize(signatureRows[rowIndex].AbilitySpan[columnIndex])}>
                      <Paper>
                        <Grid
                          container
                          sx={{
                            background:
                              headerColors[rowIndex === 0 ? "Active" : "Passive"],
                            borderTopLeftRadius: (theme) => theme.shape.borderRadius,
                            borderTopRightRadius: (theme) => theme.shape.borderRadius,
                          }}
                        >
                          <Grid item xs={2}>
                            <Checkbox
                              sx={{
                                padding: "3px",
                                "&.MuiCheckbox-colorPrimary": {
                                  color: "#FFFFFF",
                                },
                                "&.Mui-checked": {
                                  color: "#FFFFFF",
                                },
                                "&.Mui-disabled": {
                                  color: "#808080",
                                },
                              }}
                              disabled={
                                !isConnected(
                                  rowIndex,
                                  columnIndex,
                                  signatureRows[rowIndex].Directions,
                                  checkedSigCells,
                                  true
                                )
                              }
                              checked={
                                checkedSigCells[rowIndex]
                                  ? checkedSigCells[rowIndex][columnIndex]
                                  : false
                              }
                              onChange={() => toggleSigCell(rowIndex, columnIndex)}
                            />
                          </Grid>
                          <Grid item xs={10}>
                            <Typography variant="h6" color="white">
                              {
                                sigAbNodes.find((node) => node.Key === cell)
                                  .Name
                              }
                            </Typography>
                          </Grid>
                        </Grid>
                        <span
                          dangerouslySetInnerHTML={{
                            __html: MarkedText.renderer(
                              sigAbNodes.find((node) => node.Key === cell)
                                .Description,
                            ),
                          }}
                        />
                        <Typography>
                          XP: {signatureRows[rowIndex].Costs[columnIndex]}
                        </Typography>
                      </Paper>
                    </Grid>
                    <Grid
                      item
                      xs={1 / signatureRows[rowIndex].AbilitySpan[columnIndex]}
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        height: "50px",
                      }}
                    >
                      <Paper
                        square
                        sx={{
                          border: "6px solid gray",
                          height: "0px",
                          minWidth: "90px",
                        }}
                        style={{
                          visibility: signatureRows[rowIndex].Directions[
                            columnIndex
                          ].Right
                            ? "visible"
                            : "hidden",
                        }}
                      ></Paper>
                    </Grid>
                  </Grid>
                  {rowIndex > 0 && (<Grid container>
                    <Grid
                      item
                      xs={12}
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        flexDirection: "column",
                        minHeight: "30px",
                      }}
                    >
                      <Paper
                        square
                        sx={{
                          border: "6px solid gray",
                          width: "0px",
                          height: "100%",
                          position: "absolute",
                        }}
                        style={{
                          visibility: signatureRows[
                            rowIndex
                          ].Directions[columnIndex].Down
                            ? "visible"
                            : "hidden",
                        }}
                      ></Paper>
                    </Grid>
                  </Grid>)}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table >
    </TableContainer >
  );
}

function App({ character, handleCharacterChange, appFiles }) {
  const [activeTab, setActiveTab] = useState(0);
  const [selectedCareer, setSelectedCareer] = useState(character.Career.Key);
  const [careerSpecs, setCareerSpecs] = useState(character.Career.Specs);
  const [allSpecs, setAllSpecs] = useState(character.Specializations);
  const [selectedSpecialization, setSelectedSpecialization] = useState(
    character.Specializations[0].Key || "",
  );
  const [spec, setSpec] = useState(character.Specializations[0]);
  const [sig, setSig] = useState(character.SignatureAbilities[0])
  const [careerSkills, setCareerSkills] = useState(character.CareerSkills);
  const [careerRanks, setCareerRanks] = useState(character.CareerRanks);
  const [specSkills, setSpecSkills] = useState(character.SpecSkills);
  const [specRanks, setSpecRanks] = useState(character.SpecRanks);
  const [checkedCells, setCheckedCells] = useState(
    character.Specializations[0].BoughtTalents || [],
  );
  const [checkedSigCells, setCheckedSigCells] = useState(
    character.SignatureAbilities[0]?.BoughtTalents || [],
  );
  const [specFilters, setSpecFilters] = useState({
    CAREER: false,
    UNIVERSAL: false,
    EOTE: false,
    AOR: false,
    FAD: false,
    FORCE: false,
  });
  const [careerFilters, setCareerFilters] = useState({
    EOTE: false,
    AOR: false,
    FAD: false,
  });
  const [skillSelectOpen, setSkillSelectOpen] = useState(false)
  const [selectedCareerSkills, setSelectedCareerSkills] = useState(character.SelectedCareerSkills || [])
  const [selectedTalent, setSelectedTalent] = useState('')
  const [outsideTalents, setOutsideTalents] = useState([[], [], [], [], []])
  const [showSkills, setShowSkills] = useState(false)

  useEffect(() => {
    if (appFiles.careers) { careers = JSON.parse(appFiles.careers) }
    if (appFiles.specializations) { specializations = JSON.parse(appFiles.specializations) }
    if (appFiles.talents) { talents = JSON.parse(appFiles.talents) }
    if (appFiles.skills) { skills = JSON.parse(appFiles.skills) }
    if (appFiles.signatureAbilities) { sigAbils = JSON.parse(appFiles.signatureAbilities) }
    if (appFiles.signatureAbilityNodes) { sigAbNodes = JSON.parse(appFiles.signatureAbilityNodes) }
    if (appFiles.weapons) { weapons = JSON.parse(appFiles.weapons) }
  }, [appFiles.careers, appFiles.specializations, appFiles.talents, appFiles.skills, appFiles.signatureAbilities, appFiles.signatureAbilityNodes, appFiles.weapons]);


  const handleCharacteristicSelect = (characteristic) => {
    let dedications = { ...character.Dedications } || {};
    const currentSpecializationKey = character.Specializations[activeTab].Key;

    for (let char in dedications) {
      if (dedications[char].includes(currentSpecializationKey)) {
        dedications[char] = dedications[char].filter(key => key !== currentSpecializationKey);
        if (dedications[char].length === 0) {
          delete dedications[char];
        }
      }
    }

    if (dedications[characteristic]) {
      if (!dedications[characteristic].includes(currentSpecializationKey)) {
        dedications[characteristic].push(currentSpecializationKey);
      }
    } else {
      dedications[characteristic] = [currentSpecializationKey];
    }

    handleCharacterChange({ Dedications: dedications });
  };


  const handleSelectedCareerSkills = (skills) => {
    handleCharacterChange({ SelectedCareerSkills: skills })
    setSkillSelectOpen(false);
  };

  const selectedCareerSkillsToggle = (skill) => {
    let newSkills = { ...selectedCareerSkills }
    if (selectedCareerSkills[skill]) {
      delete newSkills[skill]
    }
    else {
      newSkills[skill] = selectedTalent.Key
    }
    setSelectedCareerSkills(newSkills)
  }

  const handleDedicationClear = () => {
    let dedications = { ...character.Dedications } || {};
    let newDedications = {};

    Object.keys(dedications).forEach(key => {
      if (Array.isArray(dedications[key])) {
        const filteredArray = dedications[key].filter(spec => spec !== character.Specializations[activeTab].Key);
        if (filteredArray.length > 0) {
          newDedications[key] = filteredArray;
        }
      }
    });
    return newDedications
  }

  const handleCareerSkillToggle = (skill) => {
    if (careerRanks.includes(skill)) {
      setCareerRanks(
        careerRanks.filter((selectedSkill) => selectedSkill !== skill),
      );
      handleCharacterChange({
        CareerRanks: careerRanks.filter(
          (selectedSkill) => selectedSkill !== skill,
        ),
        XP:
          character.XP +
          (5 * character.Skills.find((s) => s.skill === skill).value || 0),
      });
    } else {
      if (careerRanks.length < (character.Species.Key === "DROID" ? 6 : careerByKey(selectedCareer).FreeRanks)) {
        setCareerRanks([...careerRanks, skill]);
        handleCharacterChange({
          CareerRanks: [...careerRanks, skill],
          XP:
            character.XP -
            (5 * character.Skills.find((s) => s.skill === skill).value || 0),
        });
      }
    }
  };

  const handleSpecializationSkillToggle = (skill) => {
    if (specRanks.includes(skill)) {
      setSpecRanks(
        specRanks.filter((selectedSkill) => selectedSkill !== skill),
      );
      handleCharacterChange({
        SpecRanks: specRanks.filter((selectedSkill) => selectedSkill !== skill),
        XP:
          character.XP +
          (5 * character.Skills.find((s) => s.skill === skill).value || 0),
      });
    } else {
      if (specRanks.length < (character.Species.Key === "DROID" ? 3 : 2)) {
        setSpecRanks([...specRanks, skill]);
        handleCharacterChange({
          SpecRanks: [...specRanks, skill],
          XP:
            character.XP -
            (5 * character.Skills.find((s) => s.skill === skill).value || 0),
        });
      }
    }
  };

  const getXPRefund = (cells) => {
    if (!cells) {
      return 0;
    }
    const weights = [5, 10, 15, 20, 25]; // Custom weights per row
    const trueCounts = cells.map((row) => row.filter((value) => value).length);
    return trueCounts.reduce(
      (sum, count, index) => sum + count * weights[index],
      0,
    );
  };

  const careerByKey = (key) => {
    let career = [...careers].find((c) => c.Key === key);
    return career;
  };

  const handleCareerChange = (event) => {
    let career = careerByKey(event.target.value);
    let xpRefund = getXPRefund(checkedCells);
    setCheckedCells([]);
    setSelectedCareer(event.target.value);
    setCareerSpecs(career.Specializations);
    setCareerSkills(career.CareerSkills);
    setCareerRanks([]);
    setSelectedSpecialization("");
    setSpec({});
    setShowSkills(true)

    handleCharacterChange({
      Career: {
        ...career,
        Specs: career.Specializations,
      },
      CareerSkills: career.CareerSkills,
      CareerRanks: [],
      Specializations: [{}],
      XP: character.XP + xpRefund,
      ForceRating: career.Attributes.ForceRating,
    });
  };

  const handleSpecializationChange = (event) => {
    let newSpec = specializations.find(
      (spec) => spec.Key === event.target.value,
    );
    newSpec["BoughtTalents"] = Array.from({ length: 5 }, () =>
      Array(4).fill(false),
    );
    let xpRefund = getXPRefund(checkedCells);
    let newSpecs = [...allSpecs];
    newSpecs[0] = newSpec;
    setCheckedCells(newSpec.BoughtTalents);
    setSelectedSpecialization(event.target.value);
    setSpecSkills(newSpec.CareerSkills);
    setSpecRanks([]);
    setSpec(newSpec);
    setAllSpecs(newSpecs);
    setShowSkills(true)
    handleCharacterChange({
      Specializations: newSpecs,
      SpecSkills: newSpec.CareerSkills,
      SpecRanks: [],
      XP: character.XP + xpRefund,
    });
  };

  const handleSigChange = (sig) => {
    let newSig = { ...sig }
    newSig["BoughtTalents"] = Array.from({ length: 3 }, () =>
      Array(4).fill(false),
    );
    let xpRefund = getXPRefund(checkedSigCells);
    if (xpRefund > 0) { xpRefund += 25 }
    setCheckedSigCells(newSig.BoughtTalents);
    setSig(newSig);
    handleCharacterChange({
      SignatureAbilities: [newSig],
      XP: parseInt(character.XP) + parseInt(xpRefund),
    });
  };

  const handleTabChange = (event, newValue) => {
    setActiveTab(parseInt(newValue));
    if (newValue < allSpecs.length) {
      setCheckedCells(character.Specializations[newValue].BoughtTalents || []);
      setOutsideTalents([[], [], [], [], []])
    }
  };

  const careerDiscount = (key) => {
    let careerDiscount = 0;
    if (careerSpecs.includes(key) || universalKeys().includes(key)) {
      careerDiscount = -10;
    }
    return careerDiscount;
  };

  const purchaseSpec = (spec) => {
    let cost = (allSpecs.length + 1) * 10 + 10 + careerDiscount(spec.Key);
    let specArray = [...allSpecs];
    spec.BoughtTalents = Array.from({ length: 5 }, () => Array(4).fill(false));
    if (spec.Attributes && spec.Attributes.ForceRating) {
      handleCharacterChange({ ForceRating: 1 });
    }
    specArray.push(spec);
    setAllSpecs(specArray);
    handleCharacterChange({
      Specializations: specArray,
      XP: character.XP - cost,
    });
    setActiveTab(specArray.length - 1);
    setCheckedCells(spec.BoughtTalents);
  };

  const removeSpec = (tab) => {
    if (
      character.Specializations[tab].Attributes &&
      character.Specializations[tab].Attributes.ForceRating
    ) {
      handleCharacterChange({ ForceRating: 0 });
    }
    let cost =
      allSpecs.length * 10 +
      10 +
      careerDiscount(character.Specializations[tab].Key);
    let specArray = [...allSpecs];
    specArray.splice(tab, 1);
    setAllSpecs(specArray);
    handleCharacterChange({
      Specializations: specArray,
      XP: character.XP + cost + getXPRefund(checkedCells),
    });
    setCheckedCells(character.Specializations[0].BoughtTalents);
    setActiveTab(0);
  };

  const boughtSpecs = () => {
    let boughtList = [];
    for (let s in character.Specializations) {
      boughtList.push(character.Specializations[s]);
    }
    return boughtList;
  };

  const universalKeys = () => {
    let universalKeys = [];
    for (let s in specializations) {
      if (specializations[s].Universal) {
        universalKeys.push(specializations[s].Key);
      }
    }
    return universalKeys;
  };

  const eoteKeys = () => {
    let eoteCareers = careers.filter((c) =>
      ["BOUNT", "HIREDGUN", "COLO", "SMUG", "TECHNICIAN", "EXPLORER"].includes(
        c.Key,
      ),
    );
    let eoteSpecs = [];
    for (let c in eoteCareers) {
      eoteSpecs.push.apply(eoteSpecs, eoteCareers[c].Specializations);
    }
    return eoteSpecs;
  };

  const aorKeys = () => {
    let aorCareers = careers.filter((c) =>
      [
        "THEACE",
        "SPY",
        "SOLDIER",
        "ENGINEER",
        "DIPLOMAT",
        "COMMANDER",
      ].includes(c.Key),
    );
    let aorSpecs = [];
    for (let c in aorCareers) {
      aorSpecs.push.apply(aorSpecs, aorCareers[c].Specializations);
    }
    return aorSpecs;
  };

  const fadKeys = () => {
    let fadCareers = careers.filter((c) =>
      ["CONSULAR", "MYSTIC", "SEEKER", "GUARD", "WAR", "SENTINEL"].includes(
        c.Key,
      ),
    );
    let fadSpecs = [];
    for (let c in fadCareers) {
      fadSpecs.push.apply(fadSpecs, fadCareers[c].Specializations);
    }
    return fadSpecs;
  };

  const toggleFilter = (key) => {
    let filters = { ...specFilters };
    filters[key] = !filters[key];
    setSpecFilters(filters);
  };

  const toggleCareerFilter = (key) => {
    let filters = { ...careerFilters };
    filters[key] = !filters[key];
    setCareerFilters(filters);
  };

  const filteredSpecs = () => {
    let filteredArray = [...specializations];
    filteredArray = filteredArray.filter((s) => !boughtSpecs().includes(s.Key));
    if (specFilters.CAREER) {
      filteredArray = filteredArray.filter((s) => careerSpecs.includes(s.Key));
    }
    if (specFilters.UNIVERSAL) {
      filteredArray = filteredArray.filter((s) =>
        universalKeys().includes(s.Key),
      );
    }
    if (specFilters.EOTE) {
      filteredArray = filteredArray.filter((s) => eoteKeys().includes(s.Key));
    }
    if (specFilters.AOR) {
      filteredArray = filteredArray.filter((s) => aorKeys().includes(s.Key));
    }
    if (specFilters.FAD) {
      filteredArray = filteredArray.filter((s) => fadKeys().includes(s.Key));
    }
    return filteredArray;
  };

  const filteredCareers = () => {
    let filteredArray = [...careers];
    let eoteArray = [];
    let aorArray = [];
    let fadArray = [];
    if (!careerFilters.EOTE && !careerFilters.AOR && !careerFilters.FAD) {
      return filteredArray;
    }
    if (careerFilters.EOTE) {
      eoteArray = filteredArray.filter((s) =>
        [
          "BOUNT",
          "HIREDGUN",
          "COLO",
          "SMUG",
          "TECHNICIAN",
          "EXPLORER",
        ].includes(s.Key),
      );
    }
    if (careerFilters.AOR) {
      aorArray = filteredArray.filter((s) =>
        [
          "THEACE",
          "SPY",
          "SOLDIER",
          "ENGINEER",
          "DIPLOMAT",
          "COMMANDER",
        ].includes(s.Key),
      );
    }
    if (careerFilters.FAD) {
      fadArray = filteredArray.filter((s) =>
        ["CONSULAR", "MYSTIC", "SEEKER", "GUARD", "WAR", "SENTINEL"].includes(
          s.Key,
        ),
      );
    }
    filteredArray = eoteArray.concat(aorArray, fadArray);
    return filteredArray;
  };

  const getFilteredSkills = () => {
    let filteredSkills = []

    let careerSkills = []
    if (character.CareerSkills) { careerSkills = careerSkills.concat([...character.CareerSkills]) }
    if (character.SpecSkills) { careerSkills = careerSkills.concat([...character.SpecSkills]) }
    if (character.ExtraCareerSkills) { careerSkills = careerSkills.concat([...character.ExtraCareerSkills]) }

    filteredSkills = skills.filter((s) => !careerSkills.includes(s.skill))
    if (selectedTalent.ChooseCareerSkills.Type !== 'NonCareer') {
      filteredSkills = filteredSkills.filter((s) => s.type === selectedTalent.ChooseCareerSkills.Type)
    }
    return filteredSkills
  }

  const DedicationDropdown = () => {
    return (
      <Select
        fullWidth
        labelId="characteristic-select-label"
        id="characteristic-select"
        label="Characteristic"
        value={Object.keys(character.Dedications || {}).find((key) =>
          character.Dedications[key].includes(character.Specializations[activeTab].Key)
        ) || ""}
        onChange={(e) => handleCharacteristicSelect(e.target.value)}
        size="small"
        sx={{ fontSize: { xs: ".7rem", md: "1rem" } }}
      >
        {characteristics.map((char) => (
          <MenuItem key={char.Key} value={char.Name}>{char.Name}</MenuItem>
        ))}
      </Select>
    )
  }

  const riggableItems = character.Weapons.concat(character.Armor)
  const tinkerableItems = riggableItems.concat(character.Gear)
  const ichorableItems = character.Weapons.filter(w => w.SkillKey === "BRAWL" || w.SkillKey === "MELEE")

  const handleTinkerToggle = (inventoryID, coordinates) => {
    let previouslyTinkered = tinkerableItems.find((i) => i.Tinkered === character.Specializations[activeTab].Key + coordinates);
    if (previouslyTinkered) {
      let type = character.Weapons.find((w) => previouslyTinkered.inventoryID === w.inventoryID) ? "Weapons" : character.Armor.find((a) => previouslyTinkered.inventoryID === a.inventoryID) ? "Armor" : "Gear";
      let newCat = [...character[type]];
      previouslyTinkered = newCat.find((i) => i.Tinkered === character.Specializations[activeTab].Key + coordinates);
      delete previouslyTinkered.Tinkered;
      previouslyTinkered.HP -= 1;
      handleCharacterChange({ [type]: newCat });
    }

    let item = tinkerableItems.find(i => i.inventoryID === inventoryID);
    if (item) {
      let type = character.Weapons.find((w) => item.inventoryID === w.inventoryID) ? "Weapons" : character.Armor.find((a) => item.inventoryID === a.inventoryID) ? "Armor" : "Gear";
      let newCat = [...character[type]];
      let newTinkered = newCat.find((i) => i.inventoryID === item.inventoryID);
      newTinkered.Tinkered = character.Specializations[activeTab].Key + coordinates;
      newTinkered.HP = newTinkered.HP ? parseInt(newTinkered.HP) + 1 : 1;
      handleCharacterChange({ [type]: newCat });
    }
  };

  const TinkererDropdown = ({ coordinates }) => {
    const selectedItem = tinkerableItems.find((i) => i.Tinkered && i.Tinkered === character.Specializations[activeTab].Key + coordinates);

    return (
      <Select
        fullWidth
        labelId="tinkerer-select-label"
        id="tinkerer-select"
        label="Tinkered Item"
        value={selectedItem ? selectedItem.inventoryID : ''}
        onChange={(e) => handleTinkerToggle(e.target.value, coordinates)}
        size="small"
        sx={{ fontSize: { xs: ".7rem", md: "1rem" } }}
      >
        {tinkerableItems.filter((i) => !i.Tinkered || i.Tinkered === character.Specializations[activeTab].Key + coordinates).map((item) => (
          <MenuItem key={item.inventoryID} value={item.inventoryID}>{item.Name}</MenuItem>
        ))}
      </Select>
    );
  };

  const handleJuryRig = (inventoryID, coordinates) => {
    let item = riggableItems.find(i => i.inventoryID === inventoryID);
    let type = character.Weapons.find((w) => item.inventoryID === w.inventoryID) ? "Weapons" : character.Armor.find((a) => item.inventoryID === a.inventoryID) ? "Armor" : "Gear";
    let prevType = "";
    let prevCat = [];
    let newCat = [];

    let previouslyRigged = riggableItems.find((i) => i.Rigged === character.Specializations[activeTab].Key + coordinates);

    if (previouslyRigged) {
      prevType = character.Weapons.find((w) => previouslyRigged.inventoryID === w.inventoryID) ? "Weapons" : character.Armor.find((a) => previouslyRigged.inventoryID === a.inventoryID) ? "Armor" : "Gear";
      prevCat = [...character[prevType]];

      delete previouslyRigged.Rigged;

      if (previouslyRigged.RigMod) {
        [previouslyRigged, statChange] = modStatChange(previouslyRigged, { Key: previouslyRigged.RigMod }, true);
      }

      if (previouslyRigged.RigQual) {
        previouslyRigged.BaseMods = previouslyRigged.BaseMods.filter((m) => !m.MiscDesc?.includes("Reduce the [AD] cost of the "))
        delete previouslyRigged.RigQual
      }

      delete previouslyRigged.RigMod

      let previouslyRiggedIndex = prevCat.findIndex((i) => i.inventoryID === previouslyRigged.inventoryID);

      if (previouslyRiggedIndex !== -1) {
        prevCat[previouslyRiggedIndex] = previouslyRigged;
      }
    }

    if (item) {
      if (type === prevType) {
        newCat = prevCat;
      } else {
        newCat = [...character[type]];
      }

      let newRigged = newCat.find((i) => i.inventoryID === item.inventoryID);
      newRigged.Rigged = character.Specializations[activeTab].Key + coordinates;

      if (type !== prevType) {
        handleCharacterChange({ [type]: newCat, [prevType]: prevCat });
      } else {
        handleCharacterChange({ [type]: newCat });
      }
    }
  };


  const JuryRiggedDropdown = ({ coordinates }) => {
    const selectedItem = riggableItems.find((i) => i.Rigged && i.Rigged === character.Specializations[activeTab].Key + coordinates);

    return (
      <Select
        fullWidth
        labelId="juryrig-select-label"
        id="juryrig-select"
        label="Jury Rigged Item"
        value={selectedItem ? selectedItem.inventoryID : ''}
        onChange={(e) => handleJuryRig(e.target.value, coordinates)}
        size="small"
        sx={{ fontSize: { xs: ".7rem", md: "1rem" } }}
      >
        {riggableItems.filter((i) => !i.Rigged || i.Rigged === character.Specializations[activeTab].Key + coordinates).map((item) => (
          <MenuItem key={item.inventoryID} value={item.inventoryID}>{item.Name}</MenuItem>
        ))}
      </Select>
    );
  };

  const JurySelectionDropdown = ({ coordinates }) => {
    let selectedItem = riggableItems.find((i) => i.Rigged && i.Rigged === character.Specializations[activeTab].Key + coordinates);
    if (!selectedItem) { return }
    const type = character.Weapons.find((w) => selectedItem.inventoryID === w.inventoryID) ? "Weapons" : "Armor"
    const options = (type === "Weapons") ? ["DAMADD", "CRITSUB", "ENCSUB2", "QUALADVSUB"] : ["MELEEDEFADD", "RANGEDEFADD", "ENCSUB2"]

    return (
      <Select
        fullWidth
        labelId="juryrig-mod-label"
        id="juryrig-mod"
        label="Jury Rigged Modification"
        value={selectedItem.RigMod || ''}
        onChange={(e) => {
          if (selectedItem.RigQual) {
            selectedItem.BaseMods = selectedItem.BaseMods.filter((m) => !m.MiscDesc?.includes("Reduce the [AD] cost of the "))
            delete selectedItem.RigQual
          }
          let newCategory = character[type]
          let index = newCategory.findIndex((i) => i.inventoryID === selectedItem.inventoryID)
          if (selectedItem.RigMod) {
            [selectedItem, statChange] = modStatChange(selectedItem, { Key: selectedItem.RigMod }, true)
          }
          [selectedItem, statChange] = modStatChange(selectedItem, { Key: e.target.value })
          selectedItem.RigMod = e.target.value
          newCategory[index] = selectedItem
          handleCharacterChange({ [type]: newCategory })
        }}
        size="small"
        sx={{ fontSize: { xs: ".7rem", md: "1rem" } }}
      >
        {options.map((option) => (
          <MenuItem key={option} value={option}>{qualities.find((q) => q.Key === option).Name}</MenuItem>
        ))}
      </Select>
    );
  };

  const JuryAdvantageDropdown = ({ coordinates }) => {
    let selectedItem = riggableItems.find((i) => i.Rigged && i.Rigged === character.Specializations[activeTab].Key + coordinates);
    if (!selectedItem) { return }
    const type = character.Weapons.find((w) => selectedItem.inventoryID === w.inventoryID) ? "Weapons" : "Armor"
    const options = selectedItem.Qualities.filter((q) => qualities.find((qua) => qua.Key === q.Key && qua.IsActiveQuality))
    if (!options) { return }

    return (
      <Select
        fullWidth
        labelId="juryrig-qual-label"
        id="juryrig-qual"
        label="Jury Rigged Quality"
        value={options.find((o) => o.Key === selectedItem.RigQual) || ''}
        onChange={(e) => {
          let newCategory = character[type]
          let index = newCategory.findIndex((i) => i.inventoryID === selectedItem.inventoryID)
          if (selectedItem.RigQual) {
            selectedItem.BaseMods = selectedItem.BaseMods.filter((m) => !m.MiscDesc?.includes("Reduce the [AD] cost of the "))
          }
          selectedItem.RigQual = e.target.value.Key
          if (selectedItem.BaseMods) {
            if (Array.isArray(selectedItem.BaseMods)) {
              selectedItem.BaseMods.push({ MiscDesc: "Reduce the [AD] cost of the " + qualities.find((q) => q.Key === e.target.value.Key).Name + " by one." })
            } else {
              selectedItem.BaseMods = [selectedItem.BaseMods, { MiscDesc: "Reduce the [AD] cost of the " + qualities.find((q) => q.Key === e.target.value.Key).Name + " by one." }]
            }
          } else {
            selectedItem.BaseMods = [{ MiscDesc: "Reduce the [AD] cost of the " + qualities.find((q) => q.Key === e.target.value.Key).Name + " by one." }]
          }
          newCategory[index] = selectedItem
          handleCharacterChange({ [type]: newCategory })
        }}
        size="small"
        sx={{ fontSize: { xs: ".7rem", md: "1rem" } }}
      >
        {options.map((option, index) => (
          <MenuItem key={option.Key + index} value={option}>{qualities.find((q) => q.Key === option.Key).Name}</MenuItem>
        ))}
      </Select>
    );
  };

  const handleIchor = (inventoryID) => {
    let previouslyIchored = ichorableItems.find((i) => i.Ichored === true);
    if (previouslyIchored) {
      let newCat = [...character.Weapons];
      previouslyIchored = newCat.find((i) => i.Ichored === true);
      delete previouslyIchored.Ichored;
      handleCharacterChange({ Weapons: newCat });
    }

    let item = ichorableItems.find(i => i.inventoryID === inventoryID);
    if (item) {
      let newCat = [...character.Weapons];
      let newIchored = newCat.find((i) => i.inventoryID === item.inventoryID);
      newIchored.Ichored = true;
      handleCharacterChange({ Weapons: newCat });
    }
  };

  const IchorDropdown = () => {
    const selectedItem = ichorableItems.find((i) => i.Ichored);

    return (
      <Select
        fullWidth
        labelId="ichor-select-label"
        id="ichor-select"
        label="Ichored Item"
        value={selectedItem ? selectedItem.inventoryID : ''}
        onChange={(e) => handleIchor(e.target.value)}
        size="small"
        sx={{ fontSize: { xs: ".7rem", md: "1rem" } }}
      >
        {ichorableItems.map((item) => (
          <MenuItem key={item.inventoryID} value={item.inventoryID}>{item.Name}</MenuItem>
        ))}
      </Select>
    );
  };

  const SigVehicleDropdown = () => {
    const { Bought: boughtTalents, universalForce } = getFullBoughtTalents(character);
    const projectSize = 3 + (boughtTalents.find((t) => t.key === "LARGEPROJ")?.count || 0)
    let selectedItem = character.Vehicles.find((v) => v.SignatureVehicle);
    const options = character.Vehicles.filter((v) => parseInt(v.Silhouette) <= projectSize);

    if (!options.length) {
      return null;
    }

    return (
      <Select
        fullWidth
        labelId="sig-veh-label"
        id="sig-veh"
        label="Signature Vehicle"
        value={selectedItem?.Key || ''}
        onChange={(e) => {
          let newCategory = JSON.parse(JSON.stringify(character.Vehicles));

          if (selectedItem) {
            let oldIndex = newCategory.findIndex((i) => i.inventoryID === selectedItem.inventoryID);

            if (oldIndex !== -1) {
              let oldVehicle = { ...newCategory[oldIndex] };
              delete oldVehicle.SignatureVehicle;
              oldVehicle.BaseMods = oldVehicle.BaseMods?.filter((m) => m.MiscDesc !== "Signature Vehicle: Upgrade all Mechanics checks made on this vehicle once." &&
                m.MiscDesc !== "Fancy Paint Job: Upgrade all Charm, Deception, and Negotiation checks made in the presence of this Vehicle once." &&
                m.MiscDesc !== "Not Today: Once per session, spend a Destiny Point to save this Vehicle from destruction."
              );
              if (boughtTalents.find((t) => t.key === "CUSTLOAD")) {
                oldVehicle.HP -= 2
              }
              if (boughtTalents.find((t) => t.key === "TUNEDTHRUST")) {
                oldVehicle.Handling -= parseInt(boughtTalents.find((t) => t.key === "TUNEDTHRUST").count)
              }
              if (boughtTalents.find((t) => t.key === "BOLSTARMOR")) {
                oldVehicle.Armor -= boughtTalents.find((t) => t.key === "BOLSTARMOR").count
              }
              if (boughtTalents.find((t) => t.key === "CUSTCOOL")) {
                oldVehicle.SystemStrain -= (boughtTalents.find((t) => t.key === "CUSTCOOL").count * 2)
              }
              if (boughtTalents.find((t) => t.key === "FORTVAC")) {
                oldVehicle.HullTrauma -= boughtTalents.find((t) => t.key === "FORTVAC").count
              }
              if (boughtTalents.find((t) => t.key === "REINFRAME")) {
                delete oldVehicle.Massive
              }
              if (boughtTalents.find((t) => t.key === "OVERAMMO")) {
                if (oldVehicle.VehicleWeapons) {
                  for (let w in oldVehicle.VehicleWeapons) {
                    let weap = oldVehicle.VehicleWeapons[w]
                    if (weap.Qualities && weap.Qualities.find((q) => q.Key === "LIMITEDAMMO")) {
                      let ammo = weap.Qualities.find((q) => q.Key === "LIMITEDAMMO")?.Count
                      if (ammo) {
                        weap.Qualities.find((q) => q.Key === "LIMITEDAMMO").Count = parseInt(ammo) - boughtTalents.find((t) => t.key === "OVERAMMO").count
                      }
                    }
                  }
                }
              }
              newCategory[oldIndex] = oldVehicle;
            }
          }

          let newVehicle = newCategory.find((o) => o.Key === e.target.value);
          if (newVehicle) {
            let index = newCategory.findIndex((i) => i.inventoryID === newVehicle.inventoryID);

            if (index !== -1) {
              let updatedVehicle = { ...newVehicle, SignatureVehicle: true };

              if (updatedVehicle.BaseMods) {
                if (!Array.isArray(updatedVehicle.BaseMods)) {
                  updatedVehicle.BaseMods = [
                    { ...updatedVehicle.BaseMods },
                    { Mod: { MiscDesc: "Signature Vehicle: Upgrade all Mechanics checks made on this vehicle once." } }
                  ];
                } else {
                  updatedVehicle.BaseMods.push({ MiscDesc: "Signature Vehicle: Upgrade all Mechanics checks made on this vehicle once." })
                }

              } else {
                updatedVehicle.BaseMods = [{ MiscDesc: "Signature Vehicle: Upgrade all Mechanics checks made on this vehicle once." }];
              }
              if (boughtTalents.find((t) => t.key === "FANCPAINT")) {
                updatedVehicle.BaseMods.push({ MiscDesc: "Fancy Paint Job: Upgrade all Charm, Deception, and Negotiation checks made in the presence of this Vehicle once." })
              }
              if (boughtTalents.find((t) => t.key === "NOTTODAY")) {
                updatedVehicle.BaseMods.push({ MiscDesc: "Not Today: Once per session, spend a Destiny Point to save this Vehicle from destruction." })
              }
              if (boughtTalents.find((t) => t.key === "CUSTLOAD")) {
                updatedVehicle.HP = parseInt(updatedVehicle.HP) + 2
              }
              if (boughtTalents.find((t) => t.key === "TUNEDTHRUST")) {
                updatedVehicle.Handling = parseInt(updatedVehicle.Handling) + parseInt(boughtTalents.find((t) => t.key === "TUNEDTHRUST").count)
              }
              if (boughtTalents.find((t) => t.key === "BOLSTARMOR")) {
                updatedVehicle.Armor = parseInt(updatedVehicle.Armor) + parseInt(boughtTalents.find((t) => t.key === "BOLSTARMOR").count)
              }
              if (boughtTalents.find((t) => t.key === "CUSTCOOL")) {
                updatedVehicle.SystemStrain = parseInt(updatedVehicle.SystemStrain) + (parseInt(boughtTalents.find((t) => t.key === "CUSTCOOL").count) * 2)
              }
              if (boughtTalents.find((t) => t.key === "FORTVAC")) {
                updatedVehicle.HullTrauma = parseInt(updatedVehicle.HullTrauma) + parseInt(boughtTalents.find((t) => t.key === "FORTVAC").count)
              }
              if (boughtTalents.find((t) => t.key === "REINFRAME")) {
                updatedVehicle.Massive = 1
              }
              if (boughtTalents.find((t) => t.key === "OVERAMMO")) {
                if (updatedVehicle.VehicleWeapons) {
                  for (let w in updatedVehicle.VehicleWeapons) {
                    let weap = updatedVehicle.VehicleWeapons[w]
                    if (weap.Qualities && weap.Qualities.find((q) => q.Key === "LIMITEDAMMO")) {
                      let ammo = weap.Qualities.find((q) => q.Key === "LIMITEDAMMO")?.Count
                      if (ammo) {
                        weap.Qualities.find((q) => q.Key === "LIMITEDAMMO").Count = parseInt(ammo) + 1
                      }
                    } else {
                      let stats = weapons.find((w) => w.Key === weap.Key)
                      if (stats.Qualities?.find((q) => q.Key === "LIMITEDAMMO")) {
                        let newQual = { Key: "LIMITEDAMMO", Count: parseInt(stats.Qualities.find((q) => q.Key === "LIMITEDAMMO").Count) + 1 }
                        weap.Qualities ? weap.Qualities.push(newQual) : weap.Qualities = [newQual]
                      }
                    }
                  }
                }
              }
              newCategory[index] = updatedVehicle;
            }
          }

          handleCharacterChange({ Vehicles: newCategory });
        }}
        size="small"
        sx={{ fontSize: { xs: ".7rem", md: "1rem" } }}
      >
        {options.map((option, index) => (
          <MenuItem key={option.Key + index} value={option.Key}>
            {option.Name}
          </MenuItem>
        ))}
      </Select>
    );
  };

  return (
    <Container>
      <Typography variant="h3">Career and Specialization</Typography>
      <Tabs
        value={activeTab}
        onChange={handleTabChange}
        variant="scrollable"
        scrollButtons="auto"
        style={{ marginBottom: "1rem" }}
      >
        <Tab
          label={
            (careerByKey(selectedCareer)
              ? careerByKey(selectedCareer).Name + ": "
              : "Career") +
            (selectedSpecialization
              ? specializations.find(
                (spec) => spec.Key === selectedSpecialization,
              ).Name
              : "")
          }
          wrapped
        />
        {[...character.Specializations]
          .filter((s, index) => index > 0)
          .map((spec, index) => (
            <Tab
              key={index}
              label={spec.Name || `Tab ${index + 1}`}
              value={index + 1}
            />
          ))}
        {spec.Name && <Tab label="Purchase Specialization" wrapped />}
      </Tabs>
      <Modal
        open={skillSelectOpen}
        onClose={() => setSkillSelectOpen(false)}
        aria-labelledby="skill-modal-title"
      >
        <Box sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 400,
          maxHeight: '80vh',
          bgcolor: 'background.paper',
          boxShadow: 24,
          p: 4,
          display: 'flex',
          flexDirection: 'column',
        }}>
          <Typography id="skill-modal-title" variant="h6" component="h2">
            Select {selectedTalent ? selectedTalent.ChooseCareerSkills.SkillCount : ''} Career Skills
          </Typography>
          {selectedTalent && (
            <Box sx={{
              mt: 2,
              flexGrow: 1,
              overflow: 'auto',
              maxHeight: 'calc(80vh - 200px)',
              pr: 2,
              pb: 2,
            }}>
              {getFilteredSkills().map((skill) => (
                <Grid container key={skill.Key} alignItems="center" spacing={1} sx={{ mb: 1 }}>
                  <Grid item>
                    <Checkbox
                      size="small"
                      disabled={!skillSelectOpen || ((Object.values(selectedCareerSkills).filter((value) => value === selectedTalent.Key).length === parseInt(selectedTalent.ChooseCareerSkills.SkillCount))
                        && !selectedCareerSkills[skill.skill]) || (selectedCareerSkills[skill.skill] && selectedCareerSkills[skill.skill] !== selectedTalent.Key)}
                      checked={selectedCareerSkills[skill.skill]}
                      onChange={() => selectedCareerSkillsToggle(skill.skill)}
                    />
                  </Grid>
                  <Grid item>
                    <Typography>{skill.skill}</Typography>
                  </Grid>
                </Grid>
              ))}
            </Box>
          )}
          <Box sx={{ mt: 2, display: 'flex', justifyContent: 'flex-end' }}>
            <Button onClick={() => handleSelectedCareerSkills(selectedCareerSkills)} sx={{ mr: 1 }}>
              Submit
            </Button>
            <Button onClick={() => setSkillSelectOpen(false)}>
              Cancel
            </Button>
          </Box>
        </Box>
      </Modal>
      {activeTab === 0 && (
        <>
          <Grid container>
            <Grid item xs={6} sm={4}>
              <FormControl
                style={{
                  marginLeft: "20px",
                  marginRight: "20px",
                  minWidth: "150px",
                  width: "100%",
                  paddingRight: "10px"
                }}
              >
                <InputLabel>Select Career</InputLabel>
                <Select value={selectedCareer} onChange={handleCareerChange}>
                  {filteredCareers().map((career, index) => (
                    <MenuItem key={career.Key} value={career.Key}>
                      {career.Name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            {careerSkills[1] && (
              <Grid item xs={6} sm={4}>
                <FormControl
                  style={{
                    marginLeft: "20px",
                    marginRight: "20px",
                    minWidth: "150px",
                    width: "100%",
                    paddingRight: "10px"
                  }}
                >
                  <InputLabel>Select Specialization</InputLabel>
                  <Select
                    value={selectedSpecialization}
                    onChange={handleSpecializationChange}
                  >
                    {careerSpecs.map((specialization, index) => (
                      <MenuItem key={specialization} value={specialization}>
                        {
                          specializations.find(
                            (spec) => spec.Key === specialization,
                          ).Name
                        }
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            )}
          </Grid>
          <div style={{ marginLeft: "20px" }}>
            <FormControlLabel
              key="EOTE"
              control={
                <Checkbox
                  checked={careerFilters.EOTE}
                  onChange={() => toggleCareerFilter("EOTE")}
                />
              }
              label="Edge of the Empire"
            />
            <FormControlLabel
              key="AOR"
              control={
                <Checkbox
                  checked={careerFilters.AOR}
                  onChange={() => toggleCareerFilter("AOR")}
                />
              }
              label="Age of Rebellion"
            />
            <FormControlLabel
              key="FAD"
              control={
                <Checkbox
                  checked={careerFilters.FAD}
                  onChange={() => toggleCareerFilter("FAD")}
                />
              }
              label="Force and Destiny"
            />
            <Collapse in={!showSkills}>
              <Button onClick={() => setShowSkills(!showSkills)}> Select Free Skill Ranks</Button>
              {character.Career.CareerSkills && (
                <Typography>
                  Career Skills:{" "}
                  {character.Career.CareerSkills.join(
                    ", ",
                  )}
                </Typography>
              )}
              {character.Specializations[activeTab].CareerSkills && (
                <Typography>
                  Specialization Career Skills:{" "}
                  {character.Specializations[activeTab].CareerSkills.join(
                    ", ",
                  )}
                </Typography>
              )}
            </Collapse>
          </div>
          <Grid container>
            <Grid item xs={1}>
              <Collapse in={showSkills}>
                <Button onClick={() => setShowSkills(!showSkills)}>^</Button>
              </Collapse>
            </Grid>
            <Grid item xs={11}>
              <Collapse in={showSkills}>
                <Grid container>
                  <Grid item xs={12} sm={4}>
                    {careerSkills[1] && (
                      <Typography variant="h6">
                        Career Skills (Select {character.Species.Key === "DROID" ? 6 : careerByKey(selectedCareer).FreeRanks}{" "}
                        free ranks)
                      </Typography>
                    )}
                    <FormGroup>
                      {careerSkills.map((skill) => (
                        <FormControlLabel
                          key={skill}
                          control={
                            <Checkbox
                              checked={careerRanks.includes(skill)}
                              onChange={() => handleCareerSkillToggle(skill)}
                            />
                          }
                          label={skill}
                        />
                      ))}
                    </FormGroup>
                  </Grid>
                  {spec.CareerSkills && (
                    <Grid item xs={12} sm={4}>
                      <Typography variant="h6">
                        Specialization Career Skills (Select {character.Species.Key === "DROID" ? 3 : 2} free ranks)
                      </Typography>
                      <FormGroup>
                        {spec.CareerSkills.map((skill) => (
                          <FormControlLabel
                            key={skill}
                            control={
                              <Checkbox
                                checked={specRanks.includes(skill)}
                                onChange={() =>
                                  handleSpecializationSkillToggle(skill)
                                }
                                color="primary"
                              />
                            }
                            label={skill}
                          />
                        ))}
                      </FormGroup>
                    </Grid>
                  )}
                </Grid>
              </Collapse></Grid>
          </Grid>
          {spec.TalentRows && (
            <TableComponent
              tableData={spec.TalentRows}
              character={character}
              handleCharacterChange={handleCharacterChange}
              checkedCells={checkedCells}
              setCheckedCells={setCheckedCells}
              outsideTalents={outsideTalents}
              setOutsideTalents={setOutsideTalents}
              checkedSigCells={checkedSigCells}
              setCheckedSigCells={setCheckedSigCells}
              talents={talents}
              handleDedicationClear={handleDedicationClear}
              setSkillSelectOpen={setSkillSelectOpen}
              setSelectedTalent={setSelectedTalent}
              spec={spec}
              sig={sig}
              handleSigChange={handleSigChange}
              DedicationDropdown={DedicationDropdown}
              TinkererDropdown={TinkererDropdown}
              JuryRiggedDropdown={JuryRiggedDropdown}
              JurySelectionDropdown={JurySelectionDropdown}
              JuryAdvantageDropdown={JuryAdvantageDropdown}
              SigVehicleDropdown={SigVehicleDropdown}
              IchorDropdown={IchorDropdown}
              activeTab={activeTab}
            />
          )}
        </>
      )}
      {activeTab > 0 && activeTab < character.Specializations.length && (
        <>
          {character.Specializations[activeTab].TalentRows && (
            <>
              <Button onClick={(event) => removeSpec(activeTab)}>
                Remove {character.Specializations[activeTab].Name}{" "}
                Specialization
              </Button>
              {character.Specializations[activeTab].CareerSkills && (
                <Typography>
                  Specialization Career Skills:{" "}
                  {character.Specializations[activeTab].CareerSkills.join(
                    ", ",
                  )}
                </Typography>
              )}
              <TableComponent
                tableData={character.Specializations[activeTab].TalentRows}
                character={character}
                handleCharacterChange={handleCharacterChange}
                checkedCells={checkedCells}
                setCheckedCells={setCheckedCells}
                outsideTalents={outsideTalents}
                setOutsideTalents={setOutsideTalents}
                checkedSigCells={checkedSigCells}
                setCheckedSigCells={setCheckedSigCells}
                talents={talents}
                handleDedicationClear={handleDedicationClear}
                setSkillSelectOpen={setSkillSelectOpen}
                setSelectedTalent={setSelectedTalent}
                spec={spec}
                sig={sig}
                handleSigChange={handleSigChange}
                DedicationDropdown={DedicationDropdown}
                TinkererDropdown={TinkererDropdown}
                JuryRiggedDropdown={JuryRiggedDropdown}
                JurySelectionDropdown={JurySelectionDropdown}
                JuryAdvantageDropdown={JuryAdvantageDropdown}
                SigVehicleDropdown={SigVehicleDropdown}
                IchorDropdown={IchorDropdown}
                activeTab={activeTab}
              />
            </>
          )}
        </>
      )}
      {activeTab === character.Specializations.length && (
        <>
          <Autocomplete
            style={{ marginLeft: "20px", marginRight: "20px", width: "300px" }}
            disablePortal
            options={filteredSpecs()}
            getOptionLabel={(option) => option.Name || ""}
            renderInput={(params) => (
              <TextField {...params} label="Purchase Specialization" />
            )}
            renderOption={(props, option, { selected }) => (
              <li {...props} key={option.Key}>
                <MenuItem key={option.Key} selected={selected} component="div">
                  {option.Name}
                </MenuItem>
              </li>
            )}
            onChange={(event, newValue) => {
              purchaseSpec(newValue);
            }}
          />
          <FormControlLabel
            key="CAREER"
            control={
              <Checkbox
                checked={specFilters.CAREER}
                onChange={() => toggleFilter("CAREER")}
              />
            }
            label="Career"
          />
          <FormControlLabel
            key="UNIVERSAL"
            control={
              <Checkbox
                checked={specFilters.UNIVERSAL}
                onChange={() => toggleFilter("UNIVERSAL")}
              />
            }
            label="Universal"
          />
          <FormControlLabel
            key="EOTE"
            control={
              <Checkbox
                checked={specFilters.EOTE}
                onChange={() => toggleFilter("EOTE")}
              />
            }
            label="Edge of the Empire"
          />
          <FormControlLabel
            key="AOR"
            control={
              <Checkbox
                checked={specFilters.AOR}
                onChange={() => toggleFilter("AOR")}
              />
            }
            label="Age of Rebellion"
          />
          <FormControlLabel
            key="FAD"
            control={
              <Checkbox
                checked={specFilters.FAD}
                onChange={() => toggleFilter("FAD")}
              />
            }
            label="Force and Destiny"
          />
        </>
      )}
    </Container>
  );
}

export default App;
